import gmail from "../assets/images/gmail.svg";
import skype from "../assets/images/skype.png";

export default [
  {
    id: 1,
    title: "roychongdev14@gmail.com",
    icon: gmail,
    linkTo: false,
  },
  {
    id: 2,
    title: "live:.cid.7b6f21360cef62ed",
    icon: skype,
    linkTo: false,
  },
];
